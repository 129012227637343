import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: true,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    show: (state) => {
      state.show = true;
    },
    toggleShow: (state) => {
      state.show = !state.show;
    },
  },
});

export const { toggleShow, show } = drawerSlice.actions;

export default drawerSlice.reducer;
